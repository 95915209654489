import "./App.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import ListGroup from "react-bootstrap/ListGroup";

function App() {
  return (
    <div>
      <Container fluid="xs">
        <Stack
          gap={3}
          className="vh-100 justify-content-center align-items-center"
        >
          <Row>
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Card.Title>Hamile Okulu yakında burada...</Card.Title>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/HamileOkulu"
                        className="text-decoration-none align-items-center"
                      >
                        <FontAwesomeIcon
                          icon={faFacebook}
                          size="1x"
                          fixedWidth
                          style={{ color: "#0a66c2" }}
                        />{" "}
                        Facebook
                      </a>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
                <ListGroup className="list-group-flush"></ListGroup>
                <Card.Body>
                  <Card.Link className="text-decoration-none align-items-center" href="mailto:hamileokulucom@gmail.com">
                    <FontAwesomeIcon icon={faEnvelope} size="1x" fixedWidth />
                    İletişim
                  </Card.Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Stack>
      </Container>
    </div>
  );
}

export default App;
